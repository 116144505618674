import React, { useState, useEffect, createContext } from "react";

export const AppContext = createContext({
  cart: {}, 
  setCart: () => {}
});

export const AppProvider = ({children}) => {
  const [cart, setCart] = useState(null);
  
  useEffect(() => {
    if (typeof window !== "undefined") {
      let cartData = localStorage.getItem("woo-next-cart");
      cartData = null !== cartData ? JSON.parse(cartData) : "";
      setCart(cartData);
    }
  }, []);

  return (
    <AppContext.Provider value={{cart, setCart}}>
      {children}
    </AppContext.Provider>
  );
};
