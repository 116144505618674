import fetch from 'isomorphic-fetch';
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client';

const uri = process.env.GATSBY_WPGRAPHQL_URL;

/**
 * Middleware operation (outgoing)
 *
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 */
export const middleware = new ApolloLink( ( operation, forward ) => {
	// Don't run this while Gatsby is building. Window is not defined
  	// @ref: https://www.gatsbyjs.org/docs/debugging-html-builds/
  	if (typeof window === 'undefined') {
	    return forward(operation)
  	}

	/**
	 * If session data exist in local storage, set value as session header.
	 */
	const session = localStorage.getItem( "woo-session" );
	if (session) {
		operation.setContext(({ headers = {} }) => ({
			headers: {
				...headers,
				'Content-Type': 'application/json',
				'woocommerce-session': `Session ${session}`,
			},
		}))	
	}
	
	return forward( operation );

} );

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink( ( operation, forward ) => {
	// Don't run this while Gatsby is building. Window is not defined
  	// @ref: https://www.gatsbyjs.org/docs/debugging-html-builds/
  	if (typeof window === 'undefined') {
	    return forward(operation)
  	}

	return forward( operation ).map( response => {
		/**
		 * Check for session header and update session in local storage accordingly.
		 */
		const context = operation.getContext();
		const { response: { headers } }  = context;
		const session = headers.get( "woocommerce-session" );

		// Bail if no session was sent
		if (!session) {
			return response
		}

		// Bail if we already have the session
		if (window.localStorage.getItem('woo-session') === session) {
			return response
		}

		// Set WC session to localStorage
		window.localStorage.setItem('woo-session', session)

		return response;
	} );
} );

// Apollo GraphQL client.
export const client = new ApolloClient({
	link: middleware.concat( afterware.concat( createHttpLink({
		uri: uri,
		fetch: fetch
	}) ) ),

	cache: new InMemoryCache({
    typePolicies: {
      Cart: {
		/* 
			Cart doesn't have an id, so per docs we need to track it somehow to make cache work as expected. This warning came up during coupons on the cart page
		*/ 
        keyFields: ['total'], 
      },
    },
  })
});
